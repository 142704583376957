import PropTypes from "prop-types"
import React from "react"
import AleLogo from "../images/icons/AleLogo.svg"

const Header = ({LogoPage}) => (
    <header>
        <LogoPage/>
    </header>
)

Header.propTypes = {
    LogoPage: PropTypes.any,
}

Header.defaultProps = {
    LogoPage: AleLogo,
}

export default Header
