import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import style from "./avisos.module.scss"
import imgPage from "../images/headers/Logos_Ale.svg"
import Header from "../components/header";
import Img from "gatsby-image"

export default ({data}) => {

    return (
        <Layout footer='default'>

            <SEO title="Aviso de Privacidad"/>
            <Header LogoPage={imgPage}/>

            <div className={style.avisosPortada}>
                <Img style={{maxHeight: '400px'}} fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <div className={style.avisosContent}>
                <h2>AVISO DE PRIVACIDAD</h2>
                <p>

                    <b>I. RESPONSABLE DE LA PROTECCIÓN DE DATOS PERSONALES</b> <br/> <br/>
                    Aerolíneas Ejecutivas es una Sociedad Anónima de Capital Variable con domicilio en Aeropuerto
                    Internacional de Toluca, Ex. Hacienda Canalejas, Calle 2 Lotes 14-18, C.P. 50200, Toluca, Estado de
                    México y teléfono (722) 279-1600, como Responsable de la protección de sus Datos Personales ha
                    designado para tales efectos el “Comité de Protección de Datos Personales” en cumplimiento a lo
                    establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares,
                    Aerolíneas Ejecutivas, S.A. de C.V. (en adelante “ALE”) hace de su conocimiento los siguientes
                    lineamientos sobre el presente Aviso de Privacidad: <br/> <br/>
                    <b>II. TRATAMIENTO Y FINALIDAD DE LOS DATOS PERSONALES</b> <br/> <br/>
                    Los datos personales de los clientes de ALE, a los cuales tiene acceso ALE por la relación
                    contractual o comercial, se mantienen como privados. A su vez ALE sólo solicita la información que
                    necesita, es decir exclusivamente la información requerida para la prestación de servicios a sus
                    clientes, también para registro de ciertas actividades promocionales o bien para el envío de
                    cotizaciones, información o productos a un destinatario distinto al cliente; en esos casos se
                    obtendrá asimismo la información del destinatario final por correo electrónico, u otros medios de
                    comunicación que ALE utiliza usualmente tales como teléfono, correo electrónico, fax, escáner y/o
                    cualquier otro medio de comunicación que se refiera en este sitio. <br/><br/>
                    <b>III. DATOS PERSONALES QUE RECABAMOS</b> <br/> <br/>
                    Entre la información solicitada al cliente para efectos de la relación contractual o comercial y que
                    ALE recaba se encuentran: <br/>
                    a) Nombre completo <br/>
                    b) Correo electrónico <br/>
                    c) Empresa en donde trabaja <br/>
                    d) Dirección: Calle y número, Colonia, Delegación o municipio, Estado, Ciudad y Código postal e)
                    Teléfono <br/>
                    f) Fax <br/>
                    Adicional a los datos anteriores, cuando el cliente realiza una solicitud de servicio se recaban los
                    siguientes datos personales: <br/>
                    a) RFC <br/>
                    b) Número de cuenta c) Banco <br/>
                    d) Nombre del Titular <br/> <br/>
                    <b>IV. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES</b> <br/> <br/>
                    ALE cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos
                    Personales proporcionados por los usuarios. <br/> <br/>
                    <b>V. TRANSFERENCIA DE DATOS PERSONALES</b> <br/><br/>
                    ALE podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país
                    los Datos Personales proporcionados por los usuarios, a sus empresas filiales, subsidiarias y/o
                    relacionadas, así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien
                    cuando sea solicitado por autoridades competentes. <br/> <br/>
                    <b>VI. USO DE LA INFORMACIÓN</b> <br/> <br/>
                    ALE recopila información estadística que nos permite rastrear la frecuencia de visitas a nuestra
                    página web y sus secciones y dicha información es almacenada de forma segura en un servidor que no
                    tiene acceso a internet. <br/>

                    ALE no alquila ni vende datos personales a terceros, no obstante puede utilizar la información
                    recopilada para efectos estadísticos y de marketing, es decir información sobre la prestación de un
                    servicio por lo que no viola la Ley Federal de Datos Personales en Posesión de los Particulares. <br/> <br/>
                    <b>VII. CONTENIDO DEL SITIO</b> <br/> <br/>
                    El contenido del presente sitio tiene como finalidad el proveer a sus visitantes la facilidad de
                    conocer en una forma general (y lo más aproximada posible) los servicios que presta ALE, más esto no
                    implica ni garantiza que la información aquí contenida pueda satisfacer los requisitos de precisión,
                    detalle, exactitud o veracidad requerida por sus visitantes, por lo que si el cliente desea obtener
                    información certera y específica (incluyendo cotizaciones, contratos, solicitudes, y/o servicios),
                    podrá realizar las consultas que estime pertinentes a través de este medio o que expresamente se
                    indiquen en este sitio. <br/>
                    La información contenida en nuestro sitio, salvo mención expresa en otro sentido, no deberá
                    considerarse en ninguna forma como la realización de una oferta de contratar hecha al cliente.
                    Cualquier comentario, sugerencia, propuesta, estudio, oferta o en general cualquier tipo de
                    información que el cliente envíe por medio de los correos electrónicos y/o teléfonos y/o faxes y/o
                    cualquier otro medio de comunicación que se refiera en este sitio, no implicará la aceptación de ALE
                    de ningún tipo de compromiso u obligación hacia el cliente remitente. <br/>
                    El sitio podría contener hipervínculos, botones y/o herramientas de búsquedas en la red, que al ser
                    utilizados por los usuarios transportan a otros sitios de Internet que podrían ser propiedad de
                    terceros. Los Datos Personales que los usuarios llegaren a proporcionar a través de estos portales o
                    sitios de Internet no se encuentran contemplados por este Aviso y su tratamiento no es
                    responsabilidad de ALE. <br/> <br/>
                    <b>VIII. DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN</b> <br/> <br/>
                    ALE cuenta con una base de datos sujeta a las disposiciones de la Ley Federal de Protección de Datos
                    Personales en Posesión de los Particulares, por lo que conforme lo dispuesto en el presente aviso de
                    privacidad, al momento que usted realiza una consulta o establece una relación comercial o
                    contractual, ALE le solicita su autorización para el uso de sus datos personales y pone a sus
                    disposición para cualquier solicitud de acceso, rectificación, cancelación u oposición (en adelante
                    “Derechos ARCO”) relacionada a sus datos personales el presente procedimiento para solicitar
                    cualquier tipo de información relacionada con el tratamiento de sus datos personales o el ejercicio
                    de cualquiera de los derechos ARCO reconocidos por la Ley antes referida, sin que por ello se limite
                    su derecho de hacerlo personalmente en nuestras oficinas. <br/>
                    Procedimiento: <br/>
                    i. El Titular de los Datos Personales o su representante legal serán los únicos facultados para
                    solicitar el ejercicio de sus derechos ARCO a ALE quien es responsable de proteger sus Datos
                    personales. <br/>
                    ii. La recepción de la solicitud para ejercer Derechos ARCO, podrá ser a través de: a) Sitio web
                    www.aerolineasejecutivas.com <br/>
                    b) Correo electrónico dirigido al Comité de Protección de Datos Personales a la siguiente dirección:
                    atenciondatos@aerolineasejecutivas.com <br/>
                    c) Escrito entregado directamente en nuestras oficinas ubicadas en: Aeropuerto Internacional de
                    Toluca, Ex. Hacienda Canalejas, Calle 2 Lotes 14-18, C.P. 50200, Toluca, Estado de México.
                    iii. ALE pone a su disposición el siguiente formato adjunto a este Aviso de Privacidad (en adelante
                    “Solicitud de Derechos ARCO”) por lo que le pedimos acompañar a dicho documento la acreditación de
                    su identidad de solicitante o representante legal, según corresponda.
                    iv. El Comité de Protección de Datos Personales dará contestación a su solicitud en un plazo máximo de 20
                    (veinte) días, contados desde la fecha en que se recibió su Solicitud de Derechos ARCO, a efecto de
                    que, si resulta procedente, se haga efectiva la misma dentro de los 15 (quince) días siguientes a la fecha en que se
                    le comunica la respuesta. <br/> <br/>
                    <b>IX. MODIFICACIONES AL PRESENTE AVISO DE PRIVACIDAD</b> <br/> <br/>
                    Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al
                    presente aviso de privacidad, para la atención de novedades legislativas o jurisprudenciales,
                    políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o
                    productos y prácticas del mercado. Estas modificaciones estarán disponibles al público a través de
                    los siguientes medios: <br/>
                    a) Anuncios visibles en nuestras instalaciones. <br/>
                    b) En nuestra página de Internet www.aerolineasejecutivas.com c) En el correo electrónico que nos
                    haya proporcionado. <br/>
                    Estimado cliente, se le notifica que el presente Aviso de Privacidad ha sido modificado el día 20 de
                    abril de 2020. <br/> <br/>
                    <b>X. DISPOSICIONES</b> <br/> <br/>
                    Conforme la propia Ley Federal de Protección de Datos Personales en Posesión de los Particulares su
                    información está sujeta a lo establecido por dicha ley, así como las demás disposiciones federales y
                    locales, por lo que, a solicitud de autoridades judiciales o administrativas en la esfera
                    correspondiente, ALE dispondrá de su información conforme lo dictaminen las autoridades
                    correspondientes. <br/>
                </p>
            </div>

        </Layout>
    )

}

export const query = graphql`
    query ImagesAviso {
    portada: file(relativePath: {eq: "avisos/avisodeprivacidad.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

}`

